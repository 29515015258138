import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Card from '../components/Card';
import Col from '../components/Col';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Seo from '../components/SEO';

const CareersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allJob(limit: 1000) {
        nodes {
          title
          description
          slug
          content {
            body
          }
          meta {
            title
            description
          }
        }
      }
    }
  `);

  const jobs = data.jobs.nodes;

  return (
    <>
      <Seo title='Careers' />
      <Hero className='text-center my-8 '>
        <h1 className='typo-h1 mb-4 z-10'>Careers</h1>
        <p className='typo-display'>
          We are continuously searching for developers
        </p>
      </Hero>
      <div className='container-sm relative z-10'>
        <Row cols={12} className='mt-16'>
          {jobs.map((post) => (
            <Col xs={12} md={6}>
              <div className='shadow-md px-2 md:px-4'>
                <Card url={post.slug}>
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Description>{post.description}</Card.Description>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default CareersPage;
