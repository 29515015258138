import classnames from 'classnames';
import React from 'react';
import PropTypes from '../../utils/PropTypes';

const gridClasses = {
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    6: 'grid-cols-6',
    12: 'grid-cols-12',
};

const Row = ({ children, cols, noGap, className }) => {
    return (
        <div className={classnames(
            className,
            'grid',
            'gap-y-16',
            gridClasses[cols],
            {
                'gap-x-8': !noGap,
            },
        )}
        >
            {children}
        </div>
    );
};

Row.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    cols: PropTypes.number.isRequired,
    noGap: PropTypes.bool,
};

Row.defaultProps = {
    children: null,
    className: '',
    noGap: false,
};

export default Row;
