import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import excerpt from '../../utils/excerpt';
import PropTypes from '../../utils/PropTypes';
import Link from '../Link';

const Card = ({ url, children }) => {
  return (
    <Link to={url}>
      {children}
    </Link>
  );
};

Card.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Image = ({ image, alt }) => (
  <div className="relative" style={{paddingBottom:"65%"}} >
    <GatsbyImage
    image={getImage(image)}
    className="aspect-ratio object-cover rounded-md"
    alt={alt}
  />
  </div>
);

Image.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
};

Image.defaultProps = {
  image: null,
  alt: '',
};

const Title = ({ children }) => (
  <h3 className="typo-h4 mt-4">
    {children}
  </h3>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

const Description = ({ children }) => {
  const description = excerpt(children, 32);

  return (
    <p className="typo-body mt-2">
      {description}
    </p>
  );
};

Description.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.Image = Image;
Card.Title = Title;
Card.Description = Description;

export default Card;
