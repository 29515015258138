import classnames from 'classnames';
import * as React from 'react';
import PropTypes from '../../utils/PropTypes';
import bg_hero from '../../images/uploads/bg-about.svg';
const Hero = ({ children, className, container }) => (
  <div
    className={classnames(
      'flex flex-col items-center justify-center relative px-2',
      className
    )}
  >
    <div className='pt-20 z-40'>{children}</div>
    <div
      className='absolute w-full h-full top-16 bg-gradient-to-t from-white dark:from-dark_bg-normal via-blue-50 dark:via-dark_bg-light to-white dark:to-dark_bg-normal'
      style={{ zIndex: '-1' }}
    >
      <img src={bg_hero} alt='' />
    </div>
  </div>
);

Hero.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.string,
};

Hero.defaultProps = {
  children: null,
  className: '',
  container: 'md',
};

export default Hero;
